import React, {useContext} from "react";
import {
  Button
} from "carbon-components-react";
import { ArrowLeft16 } from "@carbon/icons-react";
import { navigate } from "gatsby";
import ProgressBar from '../components/ProgressBar';
import Deadline from '../components/Deadline';
import TasksNav from '../components/TasksNav';
import {HeedContext} from '../gatsby-theme-carbon/components/Layout';
import { InlineLoading } from 'carbon-components-react'

function Application({ data }) {
  const heed = useContext(HeedContext);
  if(heed?.user?.id && !heed?.user?.is_eligible){
    navigate('/')
    return <></>
  }
  if(heed.loadingAnswers){
    return  <InlineLoading description="Loading..." status='active' />
  }
  const applicationLayout = (
    <div className={`application-wrapper application ${heed?.answers?.all_done ? 'complete' : ''}`}>
      <div className="bx--row">
        <div id="left-panel" className="bx--col-lg-4">
          <div>
            <Button kind="tertiary" onClick={(e) => navigate('/')}>
              <ArrowLeft16 /> <span>Back to profile</span>
            </Button>
            <ProgressBar />
            <Deadline />
            {heed?.answers?.all_done && <Button kind="primary" disabled={!heed?.answers?.answers?.attributes} onClick={(e) => navigate('/review')}>Review and Submit</Button>}
          </div>
        </div>
        <div id="right-panel" className="bx--col-lg-8">
          <div>
            <div id="application-title" className="padd">
              <h2>Your Tasks</h2>
            </div>
            <TasksNav />
          </div>
        </div>
      </div>
    </div>
  );
  return applicationLayout;
}

export default function ApplicationPage({ pageContext, location }) {
  pageContext = {
    ...pageContext,
    frontmatter: { title: "HEED Application", tabs: ["Profile", "Application"] },
  };
  return (
    <Application
      pageContext={pageContext}
      location={location}
    />
  );
}
