import React, {useContext} from 'react'
import {HeedContext} from '../gatsby-theme-carbon/components/Layout';

function ProgressBar() {
    const heed = useContext(HeedContext);
    return (
        <div id="progress-wrapper" className="padd">
            {heed?.answers?.completed_count !== heed?.tasks.length && <p>{`You have ${heed?.answers?.completed_count ? heed?.answers?.completed_count : 0} of ${heed?.tasks.length} tasks completed`}</p>}
            {heed?.answers?.completed_count === heed?.tasks.length && <p>{`You have completed all tasks`}</p>}
            <progress id="progress" value={heed?.answers?.percentage} max="100" color="#89b3d2"></progress>
        </div>
    )
}

export default ProgressBar
