import React from 'react'
import {InlineNotification} from 'gatsby-theme-carbon';
import {StaticQuery, graphql} from 'gatsby';
import ReactHtmlParser from 'react-html-parser'


function Deadline({page = 'default'}) {
    return (
      <StaticQuery
      query={graphql`
        {
          allNodeParameters {
            edges {
              node {
                body {
                  value
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div id="deadline" className={`page-${page}`}>
            <div className="padd">
                <InlineNotification kind="info">
                  <p>{(data.allNodeParameters.edges[0].node.body.value)
                  ? ReactHtmlParser(data.allNodeParameters.edges[0].node.body.value)
                  : null}</p>
                </InlineNotification>
            </div>
        </div>
      )}
    />
    )
}

export default Deadline

